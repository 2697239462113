import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDomReady from 'src/hooks/window/useDomReady'
import * as URLs from 'src/config/api/Urls'
import ContentData from './sections/escolha-receba-dicas/_contentData'
import { hookEmail, hookLista } from '../../hooks/contextHook/index'

import { EscolhaRecebaDicas, Hero, SigaInter } from './sections/_index'
import { Modal } from 'src/components/Modal'
import OpportunitiesForm from 'src/components/UI/Forms/OpportunitiesForm'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

function OportunidadesIncriveisPage () {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ lista, , setSelected, setSelectedAll ] = hookLista(ContentData)
  const [ email, setEmail ] = hookEmail()

  const newsletterFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <OpportunitiesForm
        url={`${URLs.CONTACT_FORM_POST_V5}/lp-oportunidades-incriveis`}
        lista={lista}
        setEmail={setEmail}
        email={email}
        setSelectedAll={setSelectedAll}
        closeModal={() => setOpenModal(false)}
        setSelected={setSelected}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {newsletterFormModal}
        <Hero setEmail={setEmail} email={email} setOpenModal={setOpenModal} />
        <EscolhaRecebaDicas lista={lista} setSelected={setSelected} setOpenModal={setOpenModal} />
        <SigaInter />
      </Layout>
    </Wrapper>

  )
}

export default OportunidadesIncriveisPage
