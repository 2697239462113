import React from 'react'
import { SectionWrapper } from './style'
import SocialMediaCard from './_SocialMediaCard'
import data from '../../assets/data/social.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// types
type CardsInformationProps = {
  socialMedia: string;
  description: string;
  descriptionLink: string;
  link: string;
  icon: string;
}

const AcompanheNovidades = () => {
  const cardsInformation = data
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <SectionWrapper className='pt-5 pb-4 py-md-5'>
      <div className='container pt-md-3 pb-md-2'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='text-md-center text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-32 fs-xl-40 lh-xl-50 mb-3'>
              Acompanhe nossas novidades
            </h2>
            <p className='text-grayscale--500 text-md-center font-inter fs-18 lh-22'>
              Fique por dentro de tudo que acontece no Inter e saiba de todas as informações de investimentos em primeira mão.
            </p>
          </div>
        </div>
        <div className='row justify-content-center align-items-center social-media-cards'>
          {cardsInformation.map((item: CardsInformationProps, index: number) => (
            <div key={index} className='col-12 col-md-6'>
              <SocialMediaCard
                socialMedia={item.socialMedia}
                description={item.description}
                descriptionLink={item.descriptionLink}
                target='_blank'
                rel='noreferrer'
                link={item.link}
                icon={item.icon}
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_previous: item.link,
                    element_name: item.descriptionLink,
                    section_name: 'Acompanhe nossas novidades',
                  })
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  )
}

export default AcompanheNovidades
