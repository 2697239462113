import React, { Dispatch, SetStateAction, ChangeEvent } from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// Components
import ScrollTo from 'src/components/ScrollTo'
import Icon from 'src/components/UI/MarkdownIcon'
import Input from 'src/components/Input'

// Style
import { ContentHeader, ImgSize, ScrollLink, Form } from './style'

// Query
import usePageQuery from '../../pageQuery'

type HeroProps = {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setOpenModal: Function;
}

const Hero = ({ email, setEmail, setOpenModal }: HeroProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader className='d-flex position-relative'>
      <div className='container pb-md-5 pb-lg-0'>
        <div className='row align-items-center justify-content-between'>
          <ImgSize className='col-12 col-md-5 order-md-last mb-2'>
            <Img fluid={data.heroImage.fluid} alt='Cliente acessando o app do Inter' />
          </ImgSize>
          <div className='col-12 col-md-7 mt-2 mt-md-0'>
            <h3 className='fs-32 lh-40 fs-xl-48 lh-xl-60 text-grayscale--500 fw-600'>
              Oportunidades incríveis, toda semana no seu e-mail
            </h3>
            <p className='fs-18 lh-22 text-grayscale--400 fw-400 mt-4'>
              Receba no seu e-mail toda semana conteúdos gratuitos elaborados por nossos especialistas com novidades e informações que vão te ajudar a turbinar seus investimentos.
            </p>
            {/* <div className='mt-1 mt-md-5'>
              <Form>
                <div>
                  <div className='d-none d-md-flex '>
                    <Input
                      label='Email'
                      id='email'
                      name='email'
                      type='email'
                      placeHolder='Digite seu e-mail'
                      value={email}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value) }
                    />
                  </div>
                  <div>
                    <button
                      type='button'
                      title='Começar a Investir'
                      onClick={() => {
                        setOpenModal(true)
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          element_name: 'Quero receber',
                          section_name: 'Oportunidades incríveis, toda semana no seu e-mail',
                        })
                      }}
                      className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-3 text-md-center'
                    >
                      Quero receber
                    </button>
                  </div>
                </div>
              </Form>
            </div> */}
          </div>
        </div>
        <ScrollTo
          to='#escolha-receba-dicas'
          styles='text-center d-none d-md-flex justify-content-center'
          sectionName=' Oportunidades incríveis, toda semana no seu e-mail'
          section='dobra_01'
          elementName='Conheça mais'
        >
          <ScrollLink>
            <p className='text-orange--base mb-0 fs-14 fs-lg-16 fw-600'>Conheça mais</p>
            <Icon color='orange--base' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
          </ScrollLink>
        </ScrollTo>
      </div>
    </ContentHeader>
  )
}

export default Hero
