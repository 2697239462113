import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/colors'

export const ContentHeader = styled.section`
  min-height: calc(100vh - 64px);
  align-items: center;
  background: ${gray[400]};
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.tablet} {
    min-height: 650px;
    padding-bottom: 1.5rem;

    h3 {
      padding-right: 2.2rem;
    }
  }

  @media ${device.desktopLG} {
    min-height: 100vh;

    h3{
      padding-right: 2.3rem;
    }
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {

    @media ${device.tablet} {
      max-width: 260px;
    }
    @media ${device.desktopLG} {
      max-width: 360px;
    }
    @media ${device.desktopXL} {
      max-width: 449px;
      float: right;
    }
  }
`

export const ScrollLink = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: auto;
`

export const Form = styled.form`
  input {
    max-width: 360px;
  }
`
