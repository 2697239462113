import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const SocialMediaCard = (props: any) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <div className='border bg-white rounded-4 social-media-card row'>
      <div className='col-12'>
        <p className='text-orange--extra fs-16 lh-20 fw-600 font-inter'>
          <OrangeIcon color='#FF7A00' icon={props.icon} size='LD' />
          <span dangerouslySetInnerHTML={{ __html: props.socialMedia }} />
        </p>
      </div>
      <div className='col-12 description'>
        <p className='text-grayscale--500 fs-16 lh-20 fw-600 limited-p' dangerouslySetInnerHTML={{ __html: props.description }} />
        <a
          href={props.link}
          target='_blank'
          rel='noreferrer'
          className='fs-14 text-orange--extra'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_03',
              element_action: 'click button',
              element_previous: props.description,
              element_name: props.link,
              section_name: 'Acompanhe nossas novidades',
            })
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: props.descriptionLink }} />
          <OrangeIcon color='#FF7A00' icon='arrow-right' size='MD' />
        </a>
      </div>
    </div>
  )
}

export default SocialMediaCard
