export default [
  {
    icon: 'dollar',
    titulo: 'Renda Fixa da semana',
    subtitulo: 'Nossa seleção semanal de ativos imperdíveis em renda fixa.',
    name: 'renda_fixa',
  },
  {
    icon: 'credit',
    titulo: 'Fundos de investimento',
    subtitulo: 'Os melhores fundos de investimentos com possibilidade de Pontos Loop para Black e WIN.',
    name: 'fundos_investimento',
  },
  {
    icon: 'cashback',
    titulo: 'Ofertas Públicas',
    subtitulo: 'Chegue primeiro e saiba antes as ofertas que estão chamando a atenção do mercado.',
    name: 'ofertas_publicas',
  },
  {
    icon: 'list-check',
    titulo: 'Lista VIP de lançamentos',
    subtitulo: 'Acesse em primeira mão os lançamentos da Inter Invest.',
    name: 'lista_vip',
  },
]
